import React from 'react';

import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import { openLiveChat } from '../../helpers/liveChatHelper';

const HelpMenu = () => {
  return (
    <UncontrolledDropdown nav inNavbar>
      <DropdownToggle color="secondary" nav caret>
        <i className="fa fa-question-circle fa-md mr-1" />
        <span className="ml-2 mr-1 d-none d-sm-inline-block">Help</span>
      </DropdownToggle>
      <DropdownMenu right>
        <DropdownItem onClick={openLiveChat}>
          Live Chat or Email
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

export default HelpMenu;
